import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faThumbsUp, faEye, faStar } from "@fortawesome/free-solid-svg-icons";
import Signup from 'src/components/parts/Signup';
import { connect } from "react-redux";

const mapStateToProps = state  => {
	return {
	};
};
class SideMenuDesktop extends React.Component {

	constructor(props) {

		super(props);

	}

	loadRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
	};

	render() {

		return (
			<div id="sidemenu__desktop">
				<div className="sidemenu">
					<div className="sidemenu_top">
						<button href="/account/promo" className="promotions" title="Get Tokens Free" onClick={this.loadRegister}><FontAwesomeIcon icon={faCoins} />FREE TOKENS</button>
						<div className="sidemenu_top_options">
							<NavLink to="/category/recommended/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faThumbsUp} /> Popular</NavLink>
							<NavLink to="/category/hd/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faEye} /> HD Webcams</NavLink>
							<NavLink to="/category/new/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faStar} /> New Girls</NavLink>
						</div>
					</div>
					<div className="sidemenu_categories">
						<ul id="side_list">
							<li className="side_list_title_girls">Girls Webcams</li>
							<li><NavLink to="/category/asian-girls/" activeClassName="active_sidepage">Asian Girls</NavLink></li>
							<li><NavLink to="/category/black-girls/" activeClassName="active_sidepage">Black Girls</NavLink></li>
							<li><NavLink to="/category/latina-girls/" activeClassName="active_sidepage">Latina Girls</NavLink></li>
							<li><NavLink to="/category/white-girls/" activeClassName="active_sidepage">White Girls</NavLink></li>
							<li><NavLink to="/category/fetish-girls/" activeClassName="active_sidepage">Fetish Models</NavLink></li>
							<li><NavLink to="/category/busty-girls/" activeClassName="active_sidepage">Busty Babes</NavLink></li>
							<li className="side_list_title_guys">Guys Webcams</li>
							<li><NavLink to="/category/asian-guys/" activeClassName="active_sidepage">Asian Guys</NavLink></li>
							<li><NavLink to="/category/black-guys/" activeClassName="active_sidepage">Black Guys</NavLink></li>
							<li><NavLink to="/category/latin-guys/" activeClassName="active_sidepage">Latin Guys</NavLink></li>
							<li><NavLink to="/category/white-guys/" activeClassName="active_sidepage">White Guys</NavLink></li>
							<li><NavLink to="/category/fetish-guys/" activeClassName="active_sidepage">Fetish Models</NavLink></li>
							<li><NavLink to="/category/bigcock-guys/" activeClassName="active_sidepage">Big Cock Men</NavLink></li>
							<li className="side_list_title_trans">Trans Webcams</li>
							<li><NavLink to="/category/asian-trans/" activeClassName="active_sidepage">Asian Trans</NavLink></li>
							<li><NavLink to="/category/black-trans/" activeClassName="active_sidepage">Black Trans</NavLink></li>
							<li><NavLink to="/category/latina-trans/" activeClassName="active_sidepage">Latina Trans</NavLink></li>
							<li><NavLink to="/category/white-trans/" activeClassName="active_sidepage">White Trans</NavLink></li>
							<li><NavLink to="/category/fetish-trans/" activeClassName="active_sidepage">Fetish Tranny</NavLink></li>
							<li><NavLink to="/category/bigcock-trans/" activeClassName="active_sidepage">Big Cock Shemale</NavLink></li>
							<li className="side_list_title_couple">Couples Webcams</li>
							<li><NavLink to="/category/couple/" activeClassName="active_sidepage">Straight Couple</NavLink></li>
							<li><NavLink to="/category/lesbian-couple/" activeClassName="active_sidepage">Lesbian Girls</NavLink></li>
							<li><NavLink to="/category/gay-couple/" activeClassName="active_sidepage">Gay Couple</NavLink></li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(SideMenuDesktop);