import React, { Component } from 'react';
import { connect } from "react-redux";
import Img from 'react-image';
import logo from 'public/static/img/logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faUserCircle, faDollarSign, faEnvelope } from "@fortawesome/free-solid-svg-icons";


//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
	  footer_text: state.footer_text
	};
};

//	Main Home Class
class Footer extends Component {

	constructor(props) {

		super(props);
		
	}

	render() {
	  return (
		<footer>
			<div className="footer_centered">
				<div className="footer_left">
					<div className="footer_left_top">
						<a href="/"><Img src={logo} className="footer_logo" alt="Live Porn Video Chat" title="LivePornVideochat" /></a>
					</div>
					<div className="footer_left_bottom">
						<h6>Looking for something better than porn videos? Welcome to the newest thing in porn, live sex with real models on video chat. Mingle with thousands of gorgeous host online for free. Don't be fooled by fake photos, check out there live stream and you will know exactly what they look like today. Find your favorite xxx cam and start a free private session together and live out your darkest fantasy.</h6>
						<h6>How can the first show really be free? Like any good drug dealer we give you the first private show for free, knowing you will be back like a crack addict for more. Providing live porn every minute of the day. It's not easy being horny 24/7, let us help you.</h6>
					</div>
				</div>
				<div className="footer_right">
					<div>
						<h3>Work With Us <FontAwesomeIcon icon={faDollarSign} /></h3>
						<a href="/account/model-join">Be A Model</a>
						<a href="/account/affiliate-join">Webmasters</a>
					</div>
					<div>
						<h3>Discover More <FontAwesomeIcon icon={faUserCircle} /></h3>
						<a href="//camshardcore.com">Cams Hardcore</a>
						<a href="//3dliveshow.com">3D Live Show</a>
					</div>
				</div>
			</div>
		</footer>
	  );
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Footer);