import React from 'react';
import {Helmet} from 'react-helmet';
import SideMenuDesktop from 'src/components/parts/SideMenuDesktop';
import Footer from 'src/components/parts/Footer';
import Header from 'src/components/parts/Header';


const Error = () => {
    return (
		<div>
			<Header />
		    <div className="container">
				<div className="wrap_sidemenu_also">
					<SideMenuDesktop />
					<div className="not_found">
						<h1>404 Error - Page Not Found</h1>
						<Helmet>
							<title>Page Not Found</title>
							<meta name="robots" content="noindex" />
							<meta name="googlebot" content="noindex" />
						</Helmet>
					</div>
				</div>
				<Footer />
			</div>
		</div>
    );
}

export default Error;
 