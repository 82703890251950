//
//	Handle rendering of header, basicly for switches between mobile and desktop
//
import React from 'react';
import logo from 'public/static/img/logo.png';
import MobileMenu from 'src/components/parts/MobileMenu';
import UserSort from 'src/components/parts/UserSort';
import Search from 'src/components/parts/SearchBox';
import Img from 'react-image';
import { NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import Signup from 'src/components/parts/Signup';

//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
		top_text: state.top_text,
		usersTotal: state.usersTotal,
	};
};

class Header extends React.Component {

	constructor(props) {

		super(props);

	}

	loadRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
	};

	renderMobileHeader = () => {
		return(
			<div className="header_devices">
				<div className="logo"><a href="/"><Img src={logo} className="logo" alt="Live Porn Video Chat" /></a></div>
				<div className="toolbar">
					<UserSort />
					<MobileMenu />
				</div>
				<Signup />
			</div>
		)
	}

	renderDesktopHeader = () => {
		return(
			<div className="header_container">
				<header>
					<div className="header_centered">
						<div className="header__pc_top">
							<div className="header__pc_top_left">
								<div className="header__pc_content_left">
									<div className="header__pc_logo"><a href="/"><Img src={logo} className="logo_pc" alt="LivePornVideochat Logo" title="Home Page" /></a></div>
									<div className="header__pc_heading_wrap">
										<div className="header__pc_pages">
										</div>
										<h2 className="header__pc_headingtxt">The best live porn video chats and sex cams from around the world.</h2>
									</div>
								</div>
							</div>
							<div className="header__pc_top_right">
								<div className="header__pc_content_right">
									<div className="header__pc_buttons">
										<div className="header__pc_button_login"><button onClick={this.loadRegister} title="Log In">LOG IN</button></div>
										<div className="header__pc_button_account"><button onClick={this.loadRegister} title="Create Free Account">CREATE FREE ACCOUNT</button></div>
									</div>
									<Search />
								</div>
							</div>
						</div>
					</div>
				</header>
				<nav>
					<div className="nav_centered">
						<div className="nav_flex">
							<div className="nav_genderchoose">
								<NavLink to="/category/female/" className="nav_genderchoose_link go_female" activeClassName="nav_genderchoose_female">FEMALE</NavLink>
								<NavLink to="/category/male/" className="nav_genderchoose_link go_male" activeClassName="nav_genderchoose_male">MALE</NavLink>
								<NavLink to="/category/trans/" className="nav_genderchoose_link go_trans" activeClassName="nav_genderchoose_trans">TRANS</NavLink>
								<NavLink to="/category/couple/" className="nav_genderchoose_link go_couple" activeClassName="nav_genderchoose_couple">COUPLE</NavLink>
							</div>
							<div className="nav_filters">
								<UserSort />
							</div>
						</div>
					</div>
				</nav>
				<Signup />
			</div>
		)
	}

	
	render() {

		// At width 768 or greater we will display the desktop header ( default should always be for mobile )
		if(window.innerWidth > 768 ) {

			return(
				this.renderDesktopHeader()
			);

		} else {

			return (
				this.renderMobileHeader()
			);

		}
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Header);