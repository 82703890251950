import React from 'react';
import {Helmet} from 'react-helmet';

const Blank = () => {
    return (
		<div>
			<Helmet>
				<meta name="robots" content="noindex" />
				<meta name="googlebot" content="noindex" />
			</Helmet>
		</div>
    );
}

export default Blank;
 