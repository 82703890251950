import React from 'react';
import { NavLink } from 'react-router-dom';
import Search from 'src/components/parts/SearchBox';
import Img from 'react-image';
import icon_menu from 'public/static/img/icon_menu.png';
import icon_search from 'public/static/img/icon_search.png';
import x_icon from 'public/static/img/x_icon.png';
import icon_female from 'public/static/img/icon_female.png';
import icon_male from 'public/static/img/icon_male.png';
import icon_trans from 'public/static/img/icon_trans.png';
import icon_couple from 'public/static/img/icon_couple.png';
import { connect } from "react-redux";

const mapStateToProps = state  => {
	return {
	};
};

class MobileMenu extends React.Component {

	constructor(props) {

		super(props);
		this.state = {isOpen: false }
		this.handleMenuButton = this.handleMenuButton.bind(this);

	}

	//	Update menu state
	handleMenuButton(event) {
		this.setState({ isOpen: !this.state.isOpen });
	}

	loadRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
		this.setState({ isOpen: false });
	};

	renderMobileMenuOpened = () => {

		return (
			<div className="menu_container">
				<div className="overlay" onClick={this.handleMenuButton}></div>
				<div id="menu">
					<div className="menu_top">
						<div className="menu_account">
							<div className="free_account_btn"><button onClick={this.loadRegister}>FREE TOKENS</button></div>
						</div>
						<div className="close_menu_btn" onClick={this.handleMenuButton}><Img src={x_icon} className="close_menu_icon" alt="Close Menu Icon" /></div>
					</div>
					<div className="menu_genders">
						<h1>Choose what you want to watch:</h1>
						<div className="gender_box">
							<NavLink to="/category/female/" className="female_option" activeClassName="active_gender_female" onClick={this.handleMenuButton}>
								<Img src={icon_female} className="gender_icon" alt="Female Icon" />
								FEMALE
							</NavLink>
							<NavLink to="/category/male/" className="male_option" activeClassName="active_gender_male" onClick={this.handleMenuButton}>
								<Img src={icon_male} className="gender_icon" alt="Male Icon" />
								MALE
							</NavLink>
							<NavLink to="/category/trans/" className="trans_option" activeClassName="active_gender_trans" onClick={this.handleMenuButton}>
								<Img src={icon_trans} className="gender_icon" alt="Trans Icon" />
								TRANS
							</NavLink>
							<NavLink to="/category/couple/" className="couple_option" activeClassName="active_gender_couple" onClick={this.handleMenuButton}>
								<Img src={icon_couple} className="gender_icon" alt="Couple Icon" />
								COUPLE
							</NavLink>
						</div>
					</div>
					<Search />
					<ul className="menu_list">
						<li className="category_heading_girls">GIRLS WEBCAMS</li>
						<li><NavLink to="/category/asian-girls/" activeClassName="active_menulink" onClick={this.handleMenuButton}>ASIAN GIRLS</NavLink></li>
						<li><NavLink to="/category/black-girls/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BLACK GIRLS</NavLink></li>
						<li><NavLink to="/category/latina-girls/" activeClassName="active_menulink" onClick={this.handleMenuButton}>LATINA GIRLS</NavLink></li>
						<li><NavLink to="/category/white-girls/" activeClassName="active_menulink" onClick={this.handleMenuButton}>WHITE GIRLS</NavLink></li>
						<li><NavLink to="/category/fetish-girls/" activeClassName="active_menulink" onClick={this.handleMenuButton}>FETISH MODELS</NavLink></li>
						<li><NavLink to="/category/busty-girls/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BUSTY WOMEN</NavLink></li>
						<li className="category_heading_guys">GUYS WEBCAMS</li>
						<li><NavLink to="/category/asian-guys/" activeClassName="active_menulink" onClick={this.handleMenuButton}>ASIAN GUYS</NavLink></li>
						<li><NavLink to="/category/black-guys/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BLACK GUYS</NavLink></li>
						<li><NavLink to="/category/latin-guys/" activeClassName="active_menulink" onClick={this.handleMenuButton}>LATINO GUYS</NavLink></li>
						<li><NavLink to="/category/white-guys/" activeClassName="active_menulink" onClick={this.handleMenuButton}>WHITE GUYS</NavLink></li>
						<li><NavLink to="/category/fetish-guys/" activeClassName="active_menulink" onClick={this.handleMenuButton}>FETISH BOYS</NavLink></li>
						<li><NavLink to="/category/bigcock-guys/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BIG COCK MEN</NavLink></li>
						<li className="category_heading_trans">TRANS WEBCAMS</li>
						<li><NavLink to="/category/asian-trans/" activeClassName="active_menulink" onClick={this.handleMenuButton}>ASIAN TRANS</NavLink></li>
						<li><NavLink to="/category/black-trans/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BLACK TRANS</NavLink></li>
						<li><NavLink to="/category/latina-trans/" activeClassName="active_menulink" onClick={this.handleMenuButton}>LATINA TRANS</NavLink></li>
						<li><NavLink to="/category/white-trans/" activeClassName="active_menulink" onClick={this.handleMenuButton}>WHITE TRANS</NavLink></li>
						<li><NavLink to="/category/fetish-trans/" activeClassName="active_menulink" onClick={this.handleMenuButton}>FETISH SHEMALE</NavLink></li>
						<li><NavLink to="/category/bigcock-trans/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BIG COCK GIRLS</NavLink></li>
						<li className="category_heading_couple">COUPLE WEBCAMS</li>
						<li><NavLink to="/category/couple/" activeClassName="active_menulink" onClick={this.handleMenuButton}>STRAIGHT COUPLE</NavLink></li>
						<li><NavLink to="/category/lesbian-couple/" activeClassName="active_menulink" onClick={this.handleMenuButton}>LESBIAN GIRLS</NavLink></li>
						<li><NavLink to="/category/gay-couple/" activeClassName="active_menulink" onClick={this.handleMenuButton}>GAY COUPLE</NavLink></li>
					</ul>
				</div>
			</div>
		)
	}


	renderMobileMenuClosed = () => {

		return(
			<div className="menu_openers">
				<div className="open_search" onClick={this.handleMenuButton}>
					<Img src={icon_search} className="header_icon" alt="Search Icon" />
					<h1>SEARCH</h1>
				</div>
				<div className="open_menu" onClick={this.handleMenuButton}>
					<Img src={icon_menu} className="header_icon" alt="Menu Icon" />
					<h1>MENU</h1>
				</div>
			</div>
		)

	}

	render() {

		return (
			<div>
				{this.state.isOpen ?  this.renderMobileMenuOpened() : this.renderMobileMenuClosed()}
			</div>
		);
	}
}

export default connect(mapStateToProps)(MobileMenu);